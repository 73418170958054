import React from "react"
import { usePrismicMetadata } from "../hooks/usePrismicMetadata"

const Footer = (): JSX.Element => {
  const { data: { prismicMetadata } } = usePrismicMetadata(),
    date = new Date(),
    localeString = date
      .toLocaleString(`en-US`, {
        month: `2-digit`,
        day: `2-digit`,
        year: `2-digit`,
        hour: `2-digit`,
        minute: `2-digit`,
        second: `2-digit`,
      })
      .replace(`,`, ``)

  return (
    <footer className="mt-64 p-7">
      <div className="hidden lg:flex">
        <ul className="flex flex-1 mr-7">
          {prismicMetadata?.data?.representation_name ? (
            <li className="mr-10">
              <span className="hidden xl:inline">Representation by </span>
              <a
                className="link-bold"
                target="_blank"
                rel="noopener"
                href={prismicMetadata?.data?.representation_url?.url ?? ``}
              >
                {prismicMetadata?.data?.representation_name ?? ``}
              </a>
            </li>
          ) : null}
          <li className="mr-10">
            <a
              className="link-plain"
              target="_blank"
              rel="noopener"
              href={prismicMetadata?.data?.email?.url ?? ``}
            >
              {prismicMetadata?.data?.email?.url?.replace(`mailto:`, ``) ?? ``}
            </a>
          </li>
          <li className="mr-10">
            <a
              className="link-plain"
              target="_blank"
              rel="noopener"
              href={prismicMetadata?.data?.instagram?.url ?? ``}
            >
              @
              {prismicMetadata?.data?.instagram?.url?.split(`/`)[
                prismicMetadata?.data?.instagram?.url?.split(`/`).length - 2
              ] ?? ``}
            </a>
          </li>
        </ul>
        <ul className="flex flex-1 mt-0 ml-7">
          <li className="mr-10">NY {localeString}</li>
          <li className="mr-10">
            Site by{" "}
            <a
              className="link-bold"
              target="_blank"
              rel="noopener"
              href="https://studio.sl"
            >
              SL
            </a>
          </li>
        </ul>
      </div>
      <ul className="flex lg:hidden flex-wrap -mt-7">
        {prismicMetadata?.data?.representation_name ? (
          <li className="mt-7 mr-7">
            <span className="hidden md:inline">Representation by </span>
            <a
              className="link-bold"
              target="_blank"
              rel="noopener"
              href={prismicMetadata?.data?.representation_url?.url ?? ``}
            >
              {prismicMetadata?.data?.representation_name ?? ``}
            </a>
          </li>
        ) : null}
        <li className="mt-7 mr-7">
          <a
            className="link-plain"
            target="_blank"
            rel="noopener"
            href={prismicMetadata?.data?.email?.url ?? ``}
          >
            {prismicMetadata?.data?.email?.url?.replace(`mailto:`, ``) ?? ``}
          </a>
        </li>
        <li className="mt-7 mr-7">
          <a
            className="link-plain"
            target="_blank"
            rel="noopener"
            href={prismicMetadata?.data?.instagram?.url ?? ``}
          >
            @
            {prismicMetadata?.data?.instagram?.url?.split(`/`)[
              prismicMetadata?.data?.instagram?.url?.split(`/`).length - 2
            ] ?? ``}
          </a>
        </li>
        <li className="mt-7 mr-7">NY {localeString}</li>
        <li className="mt-7 mr-7">
          Site by{" "}
          <a
            className="link-bold"
            target="_blank"
            rel="noopener"
            href="https://studio.sl"
          >
            SL
          </a>
        </li>
      </ul>
    </footer>
  )
}

export default Footer
export { Footer }
