import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData, UsePrismicPreviewDataResult } from "gatsby-plugin-prismic-previews"

const usePrismicMetadata = (): UsePrismicPreviewDataResult<GatsbyTypes.MetadataQueryQuery> => {
  const staticData = useStaticQuery<GatsbyTypes.MetadataQueryQuery>(graphql`
    query MetadataQuery {
      prismicMetadata {
        _previewable
        data {
          curriculum_vitae {
            type
            uid
            document {
              ... on PrismicPage {
                _previewable
                data {
                  title
                }
              }
            }
          }
          email {
            url
          }
          instagram {
            url
          }
          homepage {
            url
            document {
              ... on PrismicIndex {
                _previewable
                data {
                  body {
                    ... on PrismicIndexDataBodyProject {
                      slice_type
                      primary {
                        project {
                          document {
                            ... on PrismicProject {
                              _previewable
                              uid
                              data {
                                title
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          representation_name
          representation_url {
            url
          }
        }
      }
    }
  `),
  data = useMergePrismicPreviewData(staticData)

  return data
}

export default usePrismicMetadata
export { usePrismicMetadata }