import React, { ReactChildren } from "react"
import { Helmet } from "react-helmet"
import { PageProps } from "gatsby"
import { PageContext } from "../types"
import { Header } from "./header"
import { Footer } from "./footer"

const Layout = (props: PageProps<GatsbyTypes.PrismicAllDocumentTypes, PageContext>): JSX.Element => {
  return (
    <div data-root>
      <Helmet defaultTitle="Sam Stewart" titleTemplate="%s | Sam Stewart">
        <html className="antialiased" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://smstwrt.com/${props.location.pathname}`} />
        <link rel="canonical" href={`https://smstwrt.com/${props.location.pathname}`} />
        <meta name="twitter:title" content="Sam Stewart" />
        <meta property="og:title" content="Sam Stewart" />
        <meta name="description" content="b. 1988, NC" />
        <meta name="twitter:description" content="b. 1988, NC" />
        <meta property="og:description" content="b. 1988, NC" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Header />
      <main>{typeof props?.children !== `undefined` && props.children as ReactChildren}</main>
      <Footer />
    </div>
  )
}

export default Layout
export { Layout }
