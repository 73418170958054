import { GatsbyTransitionLinkProps } from "../types"
import { useMedia } from "./useMedia"

const useTransitionParameters = ({ transitionStatus }: GatsbyTransitionLinkProps): {
  headerTransitionClasses: string,
  headerTransitionStyles: Record<string, string>
  mainTransitionClasses: string,
  mainTransitionStyles: Record<string, string>,
  transitionLinkProps: {
    entry: {
      length: number,
    },
    exit: {
      length: number,
    },
  },
} => {
  const prefersReducedMotion = useMedia(
      [`(prefers-reduced-motion: reduce)`],
      [true],
      false,
    ),
    transitionLinkProps = {
      entry: {
        length: 0,
      },
      exit: {
        length: prefersReducedMotion
          ? 0
          : 0.7,
      },
    },
    headerTransitionStyles: { [index: string]: string } = {},
    mainTransitionStyles: { [index: string]: string } = {}

  let headerTransitionClasses = ``,
    mainTransitionClasses = ``

  if (prefersReducedMotion) {
    // None of that
  } else {
    // Buncha motion classes
    switch (transitionStatus) {
      case `entering`:
        headerTransitionClasses = `transform transition-transform duration-300`
        headerTransitionStyles.transform = `translateX(calc(-100% - 21px)`
        mainTransitionClasses = `transform translate-y-screen opacity-0 transition-all duration-700`
        break
      case `entered`:
        headerTransitionClasses = `opacity-100 transform translate-x-0 transition-all duration-300 delay-300`
        mainTransitionClasses = `transform translate-y-0 opacity-100 transition-all duration-700`
        break
      case `exiting`:
        headerTransitionClasses = `opacity-0 transition-opacity duration-300`
        mainTransitionClasses = `absolute left-0 w-screen overflow-hidden transform -translate-y-screen opacity-0 transition-all duration-700`
        mainTransitionStyles.top = `-${document.documentElement.scrollTop}px`
        mainTransitionStyles.height = `calc(${document.documentElement.scrollTop}px + 100vh)`
        // Scroll to top immediately to synchronize paints (don’t wait for Gatsby to scroll)
        document.documentElement.scrollTop = 0
        break
      case `exited`:
        headerTransitionClasses = `opacity-0 transition-opacity duration-300`
        mainTransitionClasses = `left-0 h-screen w-screen overflow-hidden transform -translate-y-screen opacity-0 transition-all duration-700`
        break
      default:
        break
    }
  }

  return {
    headerTransitionClasses,
    headerTransitionStyles,
    mainTransitionClasses,
    mainTransitionStyles,
    transitionLinkProps,
  }
}

export { useTransitionParameters }