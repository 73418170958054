module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-H05PWYL5KH"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"MC5YXzVxZFJBQUFDTUFHWlA1.77-9Xw0F77-977-9He-_ve-_ve-_vQ8r77-9Z0w677-9dybvv73vv70kPG8ZVQ0t77-977-977-9AQ","graphQuery":"{\nindex{\n...indexFields\nbody{\n...on text{\nnon-repeat{\n...non-repeatFields\n}\n}\n...on image{\nnon-repeat{\n...non-repeatFields\n}\n}\n...on project{\nnon-repeat{\nproject{\n...on project{\n...projectFields\n}\n}\n}\n}\n}\n}\n}","repositoryName":"samstewart","promptForAccessToken":true,"apiEndpoint":"https://samstewart.cdn.prismic.io/api/v2","lang":"*","imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sam Stewart","short_name":"Sam Stewart","start_url":"/","background_color":"#298037","theme_color":"#298037","display":"minimal-ui","icon":"src/assets/s.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d82d4b04d7d92261594cb5b42202e91a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/opt/build/repo/src/components/layout.tsx"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
