function linkResolver(doc: any) {
  switch (doc?.type) {
    case `metadata`:
      return `/`
    case `index`:
      return `/indices/${doc?.uid}`
    default:
      return `/${doc?.type}s/${doc?.uid}`
  }
}

export default linkResolver
export { linkResolver }
