import React, { useEffect, useState } from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import { useLocation } from "@reach/router"
import { linkResolver } from "../utils/linkResolver"
import { usePrismicMetadata } from "../hooks/usePrismicMetadata"
import { useTransitionParameters } from "../hooks/useTransitionParameters"

const Header = (): JSX.Element => {
  const { data: { prismicMetadata } } = usePrismicMetadata(),
    indexContents = prismicMetadata?.data?.homepage?.document?.data?.body
      ?.filter(
        (slice) => slice?.slice_type === `project`
      )
      ?.map((slice, index: number) => {
        if (!slice?.primary?.project?.document)
          return {
            uid: null,
            index: index,
            title: null,
          }

        const project = slice?.primary?.project?.document
        return {
          uid: project?.uid,
          index: `${index + 1}`.padStart(3, `0`),
          title: project?.data?.title,
        }
      }) ?? [],
    indexLength = `${indexContents.length}`.padStart(3, `0`),
    { pathname } = useLocation(),
    pathnameMatches = pathname.match(/\/projects\/([^/]+)\/?/) ?? [],
    currentUid = pathnameMatches.length >= 2 ? pathnameMatches[1] : null,
    currentIndex = indexContents.findIndex(
      (project) => project.uid === currentUid
    ),
    nextProject =
      indexContents[
        indexContents.length > currentIndex + 1 ? currentIndex + 1 : 0
      ],
    { transitionLinkProps } = useTransitionParameters({ transitionStatus: `` })

  // Force render if indexContents length is 0
  // (because preview content has not been proxied)
  const [update, setUpdate] = useState(false)
  useEffect(() => {
    if(indexContents.length === 0 && !update) setUpdate(true)
  }, [indexContents, update])

  return (
    <header className="fixed z-10 pointer-events-none top-0 left-0 w-full max-w-full flex p-7 bg-white bg-opacity-75 backdrop-filter backdrop-blur-xl md:backdrop-blur-none md:bg-transparent shadow-md md:shadow-none transition-all duration-200">
      <ul className="flex flex-1 mr-5 sm:mr-7">
        <li className="mr-7 lg:mr-10">
          <TransitionLink
            className="pointer-events-auto link-uppercase"
            to="/"
            {...transitionLinkProps}
          >
            Archive
          </TransitionLink>
        </li>
        <li className="mr-7 lg:mr-10">/{`\u00A0`}{indexLength}</li>
      </ul>
      <ul className="flex flex-1 ml-5 sm:ml-7">
        <li className="mr-7 lg:mr-10">
          <TransitionLink
            className="pointer-events-auto link-uppercase"
            to={linkResolver(prismicMetadata?.data?.curriculum_vitae) ?? ``}
            {...transitionLinkProps}
          >
            {prismicMetadata?.data?.curriculum_vitae?.document?.data?.title}
          </TransitionLink>
        </li>
        <li className="lg:mr-10">Sam Stewart<span className="hidden xs:inline"> b. 1988, NC</span></li>
      </ul>
      <ul className="hidden lg:block absolute top-0 right-0 m-7">
        <li>
          {currentIndex > -1 ? (
            <>
              {nextProject.index}{" "}
              <TransitionLink
                className="pointer-events-auto link-uppercase"
                to={`/projects/${nextProject.uid}`}
                {...transitionLinkProps}
              >
                {nextProject.title}
              </TransitionLink>
            </>
          ) : null}
        </li>
      </ul>
    </header>
  )
}

export default Header
export { Header }
